import AndBlock from "./AndBlock";
import DivideBlock from "./DivideBlock";
import EqualBlock from "./EqualBlock";
import EqualStrictBlock from "./EqualStrictBlock";
import GreaterThanBlock from "./GreaterThanBlock";
import GreaterThanOrEqualBlock from "./GreaterThanOrEqualBlock";
import LessThanBlock from "./LessThanBlock";
import LessThanOrEqualBlock from "./LessThanOrEqualBlock";
import MinusBlock from "./MinusBlock";
import ModuloBlock from "./ModuloBlock";
import MultiplyBlock from "./MultiplyBlock";
import NotBlock from "./NotBlock";
import NotEqualBlock from "./NotEqualBlock";
import NotEqualStrictBlock from "./NotEqualStrictBlock";
import NotNotBlock from "./NotNotBlock";
import OrBlock from "./OrBlock";
import PlusBlock from "./PlusBlock";

const Blocks = {};

Blocks["!="] = NotEqualBlock;
Blocks["=="] = EqualBlock;
Blocks["and"] = AndBlock;
Blocks["or"] = OrBlock;
Blocks["!"] = NotBlock;
Blocks["!=="] = NotEqualStrictBlock;
Blocks["==="] = EqualStrictBlock;
Blocks["!!"] = NotNotBlock;
Blocks[">"] = GreaterThanBlock;
Blocks[">="] = GreaterThanOrEqualBlock;
Blocks["<"] = LessThanBlock;
Blocks["<="] = LessThanOrEqualBlock;
Blocks["+"] = PlusBlock;
Blocks["-"] = MinusBlock;
Blocks["*"] = MultiplyBlock;
Blocks["/"] = DivideBlock;
Blocks["%"] = ModuloBlock;

export const BlocksTitles = {
    "!=": "is not equal to",
    "==": "is equal to",
    and: "and",
    or: "or",
    "!": "is not",
    "!==": "is strictly not equal to",
    "===": "is strictly equal to",
    "!!": "not not",
    ">": "is greater than",
    ">=": "is greater than or equal",
    "<": "is less than",
    "<=": "is less than or equal",
    "+": "plus",
    "-": "minus",
    "*": "multiply",
    "/": "divide",
    "%": "modulo",
};

export default Blocks;
