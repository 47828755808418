import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import RootLayout from "./pages/Layout/RootLayout";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import EvaluatorsPage from "./pages/Evaluators/EvaluatorsPage";
import NewEvaluatorPage from "./pages/Evaluators/NewEvaluatorPage";
import FlagsPage from "./pages/Flags/FlagsPage";
import NewFlagPage from "./pages/Flags/NewFlagPage";
import EvaluatorPage from "./pages/Evaluators/EvaluatorPage";
import FlagPage from "./pages/Flags/FlagPage";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route path="/" element={<RootLayout />}>
                <Route index={true} element={<HomePage />} />
                <Route path="/evaluators" element={<EvaluatorsPage />} />
                <Route path="/evaluators/new" element={<NewEvaluatorPage />} />
                <Route path="/evaluators/:slug" element={<EvaluatorPage />} />
                <Route path="/flags" element={<FlagsPage />} />
                <Route path="/flags/new" element={<NewFlagPage />} />
                <Route path="/flags/:slug" element={<FlagPage />} />
            </Route>
        </Route>
    )
);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
