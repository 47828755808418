export const cleanObjectKeys = (object) => {
    let newObject = Object.assign({}, object);

    for (var key in newObject) {
        let newKey = key.split("_")[0];

        let subObject = newObject[key];

        if (subObject instanceof Object && subObject instanceof Array === false) {
            subObject = cleanObjectKeys(newObject[key]);
        }

        newObject[newKey] = subObject;

        if (key !== newKey) {
            delete newObject[key];
        }
    }

    return newObject;
};
