import { FormControl, InputLabel, MenuItem, Paper, Select, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../store/api-actions";
import IfBlock from "../Evaluators/Blocks/IfBlock";
import TabPanel from "../UI/TabPanel";
import FractionalBlock from "./FractionalBlock";

const TargetingBlock = (props) => {
    const [evaluators, setEvaluators] = useState([]);
    const [evaluator, setEvaluator] = useState("");
    const [ifBlockRules, setIfBlockRules] = useState({});
    const [thenVariant, setThenVariant] = useState("");
    const [elseVariant, setElseVariant] = useState("");
    const [targetingMethod, setTargetingMethod] = useState(0);
    const [targetingConsequenceMethod, setTargetingConsequenceMethod] = useState(0);
    const [fractionalEvaluation, setFractionalEvaluation] = useState({});

    const fetchEvaluators = useCallback(async () => {
        try {
            const data = await api("/api/evaluators", {});

            setEvaluators(data);
        } catch (e) {
            console.log(e);
            throw new Error("Error while fetching evaluators");
        }
    }, []);

    useEffect(() => {
        fetchEvaluators();
    }, [fetchEvaluators]);

    useEffect(() => {
        if (props.initialTargeting && props.initialTargeting.if) {
            if (props.initialTargeting.if[0] && props.initialTargeting.if[0].$ref !== undefined) {
                setTargetingMethod(0);
                setEvaluator(props.initialTargeting.if[0].$ref);
            } else if (props.initialTargeting.if[0]) {
                setTargetingMethod(1);
                setIfBlockRules({ if: props.initialTargeting.if[0] });
            }

            if (Object.keys(props.variants).length > 0) {
                setThenVariant(props.initialTargeting.if[1]);
                setElseVariant(props.initialTargeting.if[2]);
            }

            if (props.initialTargeting.if[1].fractionalEvaluation !== undefined) {
                setTargetingConsequenceMethod(1);
                setFractionalEvaluation(props.initialTargeting.if[1]);
            }
        }
    }, [props.initialTargeting, props.variants]);

    useEffect(() => {
        let expression = {};
        let ifBlock = {};

        if (targetingMethod === 0) {
            if (evaluator === "") {
                expression = null;
            } else {
                ifBlock = {
                    $ref: evaluator,
                };
            }
        } else {
            if (Object.keys(ifBlockRules).length !== 0) {
                const newIfBlock = [];

                for (const [key, value] of Object.entries(ifBlockRules.if)) {
                    const newCondition = {};

                    newCondition[key] = value;

                    newIfBlock.push(newCondition);
                }

                ifBlock = newIfBlock[0];
            }
        }

        if (expression !== null) {
            if (targetingConsequenceMethod === 0) {
                const formattedThenVariant = thenVariant === "" ? null : thenVariant;
                const formattedElseVariant = elseVariant === "" ? null : elseVariant;

                expression["if"] = [ifBlock, formattedThenVariant, formattedElseVariant];
            } else {
                expression["if"] = [ifBlock, fractionalEvaluation, null];
            }
        }

        props.onTargetingChange(expression);
    }, [
        evaluator,
        thenVariant,
        elseVariant,
        ifBlockRules,
        targetingMethod,
        targetingConsequenceMethod,
        fractionalEvaluation,
    ]);

    const thenVariantChangeHandler = (e) => {
        setThenVariant(e.target.value);
    };

    const elseVariantChangeHandler = (e) => {
        setElseVariant(e.target.value);
    };

    const evaluatorChangeHandler = (e) => {
        setEvaluator(e.target.value);
    };

    const ifChangeHandler = (data) => {
        setIfBlockRules(data);
    };

    const targetingMethodChangeHandler = (e, newValue) => {
        e.preventDefault();

        setTargetingMethod(newValue);

        if (newValue === 0) {
            setIfBlockRules({});
        }
        if (newValue === 1) {
            setEvaluator("");
        }
    };

    const targetingConsequenceMethodChangeHandler = (e, newValue) => {
        e.preventDefault();

        setTargetingConsequenceMethod(newValue);
    };

    const fractionalBlockChangeHandler = (data) => {
        setFractionalEvaluation(data);
    };

    const shouldShowVariants = evaluator || Object.keys(ifBlockRules).length > 0;

    const variantsBlock = (
        <>
            <FormControl fullWidth sx={{ mt: 2 }} size="small">
                <InputLabel id="demo-simple-select-label">Then variant</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={thenVariant}
                    label="Then variant"
                    onChange={thenVariantChangeHandler}
                >
                    {props.variants &&
                        Object.keys(props.variants).map((variant) => {
                            return (
                                <MenuItem key={variant} value={variant}>
                                    {variant}
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }} size="small">
                <InputLabel id="demo-simple-select-label">Else variant</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={elseVariant}
                    label="Else variant"
                    onChange={elseVariantChangeHandler}
                >
                    {props.variants &&
                        Object.keys(props.variants).map((variant) => {
                            return (
                                <MenuItem key={variant} value={variant}>
                                    {variant}
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        </>
    );

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={targetingMethod} onChange={targetingMethodChangeHandler} aria-label="basic tabs example">
                    <Tab label="Evaluator" />
                    <Tab label="Custom" />
                </Tabs>
            </Box>
            <TabPanel value={targetingMethod} index={0}>
                <Paper elevation={2} sx={{ ml: 2, p: 2, mb: 2, mt: 2 }}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Add evaluator</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={evaluator}
                            label="Evaluator"
                            onChange={evaluatorChangeHandler}
                        >
                            <MenuItem key="no" value="">
                                &nbsp;
                            </MenuItem>
                            {evaluators &&
                                evaluators.map((evaluator) => {
                                    return (
                                        <MenuItem key={evaluator.slug} value={evaluator.slug}>
                                            {evaluator.name}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Paper>
            </TabPanel>
            <TabPanel value={targetingMethod} index={1}>
                <IfBlock
                    onBlockChange={ifChangeHandler}
                    nesting={0}
                    initialEvaluator={props.initialTargeting && props.initialTargeting.if[0]}
                />
            </TabPanel>

            <Paper elevation={2} sx={{ ml: 2, p: 2, mb: 2, mt: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={targetingConsequenceMethod}
                        onChange={targetingConsequenceMethodChangeHandler}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Else/Then" />
                        <Tab label="Fractional" />
                    </Tabs>
                </Box>
                <TabPanel value={targetingConsequenceMethod} index={0}>
                    {shouldShowVariants && variantsBlock}
                </TabPanel>
                <TabPanel value={targetingConsequenceMethod} index={1}>
                    <FractionalBlock
                        variants={props.variants}
                        onFractionalBlockChange={fractionalBlockChangeHandler}
                        initialFractionalEvaluation={
                            props.initialTargeting &&
                            props.initialTargeting.if[1] &&
                            props.initialTargeting.if[1].fractionalEvaluation
                        }
                    />
                </TabPanel>
            </Paper>
        </>
    );
};

export default TargetingBlock;
