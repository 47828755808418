import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const VariantBlock = (props) => {
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [valueType, setValueType] = useState("string");
    const id = props.id;

    useEffect(() => {
        if (props.initialVariant) {
            const name = Object.keys(props.initialVariant)[0];
            const value = props.initialVariant[name];

            setName(name);

            if (typeof value === "number") {
                setValue(value);

                if (value % 1 === 0) {
                    setValueType("integer");
                } else {
                    setValueType("float");
                }
            } else if (typeof value === "boolean") {
                setValue(value);
                setValueType("boolean");
            } else if (typeof value === "object" && value.var) {
                setValue(value.var);
                setValueType("var");
            } else {
                setValue(value);
                setValueType("string");
            }
        }
    }, [props]);

    const nameChangeHandler = (e) => {
        const expression = {};

        expression[name + "_" + id] = null;

        props.onBlockChange(expression);

        setName(e.target.value);

        const newExpression = {};

        newExpression[e.target.value + "_" + id] = formatArgument(value, valueType);

        props.onBlockChange(newExpression);
    };

    const valueChangeHandler = (e) => {
        setValue(e.target.value);

        const expression = {};

        expression[name + "_" + id] = formatArgument(e.target.value, valueType);

        props.onBlockChange(expression);
    };

    const valueTypeChangeHandler = (e) => {
        setValueType(e.target.value);

        const expression = {};

        expression[name + "_" + id] = formatArgument(value, e.target.value);

        props.onBlockChange(expression);
    };

    const formatArgument = (argument, type) => {
        if (type === "integer") {
            return parseInt(argument);
        }
        if (type === "float") {
            return parseFloat(argument);
        }
        if (type === "boolean") {
            return argument === "true" || argument === "1" ? true : false;
        }
        if (type === "var") {
            return { var: argument };
        }

        return argument;
    };

    const onBlockDelete = () => {
        props.onBlockDelete(id, name + "_" + id);
    };

    return (
        <Paper elevation={2} sx={{ ml: 2, p: 2, mb: 2, mt: 2 }}>
            <Grid item container spacing={2} justify="flex-end" alignItems="center">
                <Grid item xs={2} textAlign="center">
                    <Typography variant="h4" component="div" gutterBottom>
                        <Button color="error" variant="contained" type="button" size="small" onClick={onBlockDelete}>
                            <DeleteForeverIcon />
                        </Button>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                label="Name"
                                variant="outlined"
                                value={name}
                                onChange={nameChangeHandler}
                                size="small"
                            />
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <FormControl>
                            <TextField
                                id="secondArgument"
                                label="Value"
                                variant="outlined"
                                value={value}
                                onChange={valueChangeHandler}
                                size="small"
                            />
                        </FormControl>
                        <FormControl size="small" sx={{ ml: 2 }}>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={valueType}
                                label="Type"
                                onChange={valueTypeChangeHandler}
                            >
                                <MenuItem value="string">string</MenuItem>
                                <MenuItem value="integer">integer</MenuItem>
                                <MenuItem value="float">float</MenuItem>
                                <MenuItem value="boolean">boolean</MenuItem>
                                <MenuItem value="var">var</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default VariantBlock;
