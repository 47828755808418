import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VariantBlock from "./VariantBlock";

const VariantsBlock = (props) => {
    const [subBlocks, setSubBlocks] = useState({});
    const [expression, setExpression] = useState({});
    const [defaultVariant, setDefaultVariant] = useState("");

    useEffect(() => {
        if (props.initialVariants && Object.keys(props.initialVariants).length > 0) {
            const initialVariantsWithIds = {};
            const newBlocks = {};

            Object.keys(props.initialVariants).forEach((key) => {
                const id = uuidv4();

                initialVariantsWithIds[key + "_" + id] = props.initialVariants[key];

                const variant = {};
                variant[key] = props.initialVariants[key];

                newBlocks[id] = React.createElement(VariantBlock, {
                    key: id,
                    id: id,
                    onBlockDelete: onSubBlockDelete,
                    onBlockChange: onSubBlockChange,
                    initialVariant: variant,
                });
            });

            setExpression(initialVariantsWithIds);
            setSubBlocks(newBlocks);
        }

        if (props.initialDefaultVariant) {
            setDefaultVariant(props.initialDefaultVariant);
        }
    }, [props.initialVariants, props.initialDefaultVariant]);

    const cleanExpression = (expression) => {
        const newExpression = {};

        Object.keys(expression).forEach((key) => {
            const name = key.split("_")[0];

            newExpression[name] = expression[key];
        });

        return newExpression;
    };

    useEffect(() => {
        props.onVariantsChange(cleanExpression(expression));

        props.onDefaultVariantChange(defaultVariant);
    }, [expression, defaultVariant]);

    const onSubBlockChange = (data) => {
        setExpression((prevState) => {
            const objectKey = Object.keys(data)[0];

            if (data[objectKey] === null) {
                const { [objectKey]: tmp, ...newExpression } = prevState;

                return newExpression;
            } else {
                return { ...prevState, ...data };
            }
        });
    };

    const onSubBlockDelete = (id, objectKey) => {
        setExpression((prevState) => {
            const { [objectKey]: tmp, ...newExpression } = prevState;

            return newExpression;
        });

        setSubBlocks((prevState) => {
            const { [id]: tmp, ...newBlocks } = prevState;

            return newBlocks;
        });
    };

    const addBlockHandler = (e) => {
        const newBlock = {};

        const id = uuidv4();

        newBlock[id] = React.createElement(VariantBlock, {
            key: id,
            id: id,
            onBlockDelete: onSubBlockDelete,
            onBlockChange: onSubBlockChange,
        });

        setSubBlocks((prevState) => {
            return { ...prevState, ...newBlock };
        });
    };

    const defaultVariantChangeHandler = (e) => {
        setDefaultVariant(e.target.value);
    };

    const defaultVariantBlock = (
        <Paper elevation={2} sx={{ ml: 1, p: 2, mb: 2, mt: 2, ml: 2 }}>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Default variant</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Default variant"
                    value={defaultVariant}
                    onChange={defaultVariantChangeHandler}
                >
                    {Object.keys(cleanExpression(expression)).map((variant) => {
                        return (
                            <MenuItem key={variant} value={variant}>
                                {variant}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Paper>
    );

    return (
        <Paper elevation={1} sx={{ ml: 1, p: 2, mb: 2, mt: 2 }}>
            {Object.keys(subBlocks).map((block) => {
                return subBlocks[block];
            })}

            <Button variant="contained" type="button" onClick={addBlockHandler} sx={{ ml: 3 }}>
                <AddCircleIcon />
            </Button>

            {Object.keys(expression).length > 0 && defaultVariantBlock}
        </Paper>
    );
};

export default VariantsBlock;
