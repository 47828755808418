import { Button, ButtonGroup, Link } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const columns = [
    { field: "slug", headerName: "Slug", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => {
            return (
                <ButtonGroup>
                    <Button component={NavLink} to={`/flags/${params.row.slug}`}>
                        <EditIcon />
                    </Button>
                </ButtonGroup>
            );
        },
    },
];

const FlagsTable = (props) => {
    const [selectedRows, setSelectedRows] = useState([]);

    const selectingRowsHandler = (selectedRows) => {
        setSelectedRows(selectedRows);
    };

    const deleteHandler = async () => {
        props.deleteHandler(selectedRows);
    };

    const rows = [];

    for (let key in props.flags) {
        rows.push({ ...props.flags[key], key: key, id: key });
    }

    return (
        <>
            <div style={{ height: "auto", overflow: "auto" }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    checkboxSelection
                    autoHeight={true}
                    getRowHeight={() => "auto"}
                    onSelectionModelChange={(newSelectionModel) => {
                        selectingRowsHandler(newSelectionModel);
                    }}
                    selectionModel={selectedRows}
                />
            </div>

            {selectedRows.length > 0 && (
                <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    sx={{ mt: 2 }}
                    onClick={deleteHandler}
                >
                    Delete
                </Button>
            )}
        </>
    );
};

export default FlagsTable;
