import SingleOptionBlock from "./SingleOptionBlock";

const NotNotBlock = (props) => {
    return (
        <SingleOptionBlock
            type="!!"
            onBlockChange={props.onBlockChange}
            nesting={props.nesting + 1}
            onBlockDelete={props.onBlockDelete}
            id={props.id}
            parentType={props.parentType}
            initialEvaluator={props.initialEvaluator}
        />
    );
};

export default NotNotBlock;
