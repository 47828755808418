import { Card, CardContent, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const FractionBlock = (props) => {
    const [percentage, setPercentage] = useState("");

    const percentageChangeHandler = (e) => {
        setPercentage(e.target.value);

        props.onChange(props.variant, parseInt(e.target.value));
    };

    useEffect(() => {
        setPercentage(props.initialPercentage);
    }, [props.initialPercentage]);

    return (
        <Paper elevation={4} sx={{ ml: 2, p: 2, mb: 2, mt: 2 }}>
            <Grid item container spacing={2} justify="flex-end" alignItems="center">
                <Grid item xs={2}>
                    <Card variant="outlined">
                        <CardContent>{props.variant}</CardContent>
                    </Card>
                </Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth>
                        <TextField
                            id="percentage"
                            label="Percentage"
                            type="number"
                            value={percentage}
                            onChange={percentageChangeHandler}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    );
};

const FractionalBlock = (props) => {
    const [variable, setVariable] = useState("");
    const [variantsPercentages, setVariantsPercentages] = useState({});

    const forwardExpression = () => {
        const expression = {};

        expression.fractionalEvaluation = [];

        expression.fractionalEvaluation.push(variable);

        Object.keys(props.variants).forEach((key) => {
            expression.fractionalEvaluation.push([key, variantsPercentages[key]]);
        });

        props.onFractionalBlockChange(expression);
    };

    useEffect(() => {
        if (props.initialFractionalEvaluation && props.initialFractionalEvaluation.length > 0) {
            setVariable(props.initialFractionalEvaluation[0]);

            setVariantsPercentages((prevState) => {
                const variantsPercentages = {};

                Object.keys(props.variants).forEach((key) => {
                    variantsPercentages[key] = prevState[key] || 0;
                });

                if (props.initialFractionalEvaluation.length > 0) {
                    props.initialFractionalEvaluation.slice(1).forEach((variant) => {
                        variantsPercentages[variant[0]] = variant[1];
                    });
                }

                return variantsPercentages;
            });
        }
    }, [props.initialFractionalEvaluation, props.variants]);

    useEffect(() => {
        forwardExpression();
    }, [variantsPercentages]);

    const variableChangeHandler = (e) => {
        setVariable(e.target.value);
    };

    const fractionBlockChangeHandler = (variant, percentage) => {
        setVariantsPercentages((prevState) => {
            return { ...prevState, [variant]: percentage };
        });
    };

    return (
        <Paper elevation={3} sx={{ ml: 2, p: 2, mb: 2, mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                    id="variable"
                    label="Variable"
                    type="text"
                    value={variable}
                    onChange={variableChangeHandler}
                />
            </FormControl>

            {props.variants &&
                Object.keys(props.variants).length > 0 &&
                Object.keys(props.variants).map((key) => (
                    <FractionBlock
                        variant={key}
                        key={key}
                        onChange={fractionBlockChangeHandler}
                        initialPercentage={variantsPercentages[key] || 0}
                    />
                ))}
        </Paper>
    );
};

export default FractionalBlock;
