export const api = async (path, params) => {
    const sendRequest = async () => {
        const basePath = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : "";

        const response = await fetch(basePath + path, params);

        if (!response.ok) {
            throw new Error("Error while calling the API");
        }

        if (response.status === 204) {
            return;
        }

        return await response.json();
    };

    return await sendRequest();
};
