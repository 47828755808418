import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Blocks from "./BlocksConfig";
import { v4 as uuidv4 } from "uuid";

const CompoundBlock = (props) => {
    const [type, setType] = useState("");
    const [subBlocks, setSubBlocks] = useState({});
    const [evaluator, setEvaluator] = useState([]);

    useEffect(() => {
        if (Object.keys(evaluator).length > 0) {
            let evaluatorAsArray = [];

            Object.keys(evaluator).forEach((key) => {
                let object = {};

                object[key.split("_")[0]] = evaluator[key];

                evaluatorAsArray.push(object);
            });

            props.onBlockChange({ and: evaluatorAsArray });
        } else {
            props.onBlockChange({});
        }
    }, [evaluator, props]);

    useEffect(() => {
        if (props.initialEvaluator) {
            const newEvaluator = {};

            props.initialEvaluator.forEach((element) => {
                const type = Object.keys(element)[0];

                const id = type + "_" + uuidv4();

                const newBlock = {};

                newBlock[id] = React.createElement(Blocks[type], {
                    onBlockChange: onSubBlockChange,
                    onBlockDelete: onSubBlockDelete,
                    nesting: props.nesting + 1,
                    key: id,
                    id: id,
                    parentType: props.type,
                    initialEvaluator: element[type],
                });

                newEvaluator[id] = element[type];

                setSubBlocks((prevState) => {
                    return { ...prevState, ...newBlock };
                });
            });

            setEvaluator(newEvaluator);
        }
    }, [props]);

    const typeChangeHandler = (e) => {
        const newBlock = {};

        const id = e.target.value + "_" + uuidv4();

        newBlock[id] = React.createElement(Blocks[e.target.value], {
            onBlockChange: onSubBlockChange,
            onBlockDelete: onSubBlockDelete,
            nesting: props.nesting + 1,
            key: id,
            id: id,
            parentType: props.type,
        });

        setSubBlocks((prevState) => {
            return { ...prevState, ...newBlock };
        });

        setType("");
    };

    const onSubBlockChange = (data) => {
        setEvaluator((prevState) => {
            if (Object.keys(data).length === 0) {
                return {};
            }
            return { ...prevState, ...data };
        });
    };

    const onSubBlockDelete = (objectKey) => {
        setEvaluator((prevState) => {
            const { [objectKey]: tmp, ...newEvaluator } = prevState;

            return newEvaluator;
        });

        setSubBlocks((prevState) => {
            const { [objectKey]: tmp, ...newBlocks } = prevState;

            return newBlocks;
        });
    };

    const onBlockDelete = () => {
        props.onBlockDelete(props.id);
    };

    return (
        <Paper elevation={props.nesting + 1} sx={{ ml: 1 * props.nesting + 1, p: 2, mb: 2, mt: 2 }}>
            {Object.keys(subBlocks).map((block) => {
                return subBlocks[block];
            })}

            <FormControl sx={{ width: 200, ml: 1 * (props.nesting + 1) }} size="small">
                <InputLabel id="demo-simple-select-label">Add {props.type.toUpperCase()} condition</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Condition type"
                    onChange={typeChangeHandler}
                >
                    {Object.keys(Blocks).map((block) => {
                        return (
                            <MenuItem key={block} value={block}>
                                {block}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            <Button color="error" variant="contained" type="button" onClick={onBlockDelete} sx={{ ml: 3 }}>
                <DeleteForeverIcon />
            </Button>
        </Paper>
    );
};

export default CompoundBlock;
