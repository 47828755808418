import DoubleOptionsBlock from "./DoubleOptionsBlock";

const EqualStrictBlock = (props) => {
    return (
        <DoubleOptionsBlock
            type="==="
            onBlockChange={props.onBlockChange}
            nesting={props.nesting + 1}
            onBlockDelete={props.onBlockDelete}
            id={props.id}
            parentType={props.parentType}
            initialEvaluator={props.initialEvaluator}
        />
    );
};

export default EqualStrictBlock;
