import { Alert, Button, Container, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { JSONTree } from "react-json-tree";
import IfBlock from "./Blocks/IfBlock";
import { cleanObjectKeys } from "../../utils/cleanObject";
import { api } from "../../store/api-actions";

const EvaluatorPage = () => {
    const navigate = useNavigate();

    const [key, setKey] = useState("");
    const [name, setName] = useState("");
    const [evaluator, setEvaluator] = useState({});
    const [error, setError] = useState(null);
    const [initialEvaluator, setInitialEvaluator] = useState({});
    const { slug: slugParam } = useParams();

    const fetchEvaluator = useCallback(async () => {
        try {
            const data = await api("/api/evaluators/" + slugParam, {});

            const config = JSON.parse(data.config);
            const cleanInitialEvaluator = cleanObjectKeys(config);

            setEvaluator(cleanInitialEvaluator);
            setInitialEvaluator({ if: cleanInitialEvaluator });
            setKey(data.slug);
            setName(data.name);
        } catch (e) {
            console.log(e);
            throw new Error("Error while fetching flag");
        }
    }, [slugParam]);

    useEffect(() => {
        fetchEvaluator();
    }, [fetchEvaluator]);

    const keyChangeHandler = (e) => {
        setKey(e.target.value);

        setError(null);
    };

    const nameChangeHandler = (e) => {
        setName(e.target.value);

        setError(null);
    };

    const onBlockChange = (data) => {
        const dataForEvaluator = data.if;

        setEvaluator((prevState) => {
            return { ...prevState, ...dataForEvaluator };
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (key === "") {
            setError("Key is required");
            return;
        }

        if (name === "") {
            setError("Name is required");
            return;
        }

        api("/api/evaluators/" + slugParam, {
            method: "PUT",
            body: JSON.stringify({
                slug: key,
                name: name,
                config: JSON.stringify(evaluator),
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(() => {
            navigate("/evaluators");
        });
    };

    const theme = {
        scheme: "grayscale",
        author: "alexandre gavioli (https://github.com/alexx2/)",
        base00: "#101010",
        base01: "#252525",
        base02: "#464646",
        base03: "#525252",
        base04: "#ababab",
        base05: "#b9b9b9",
        base06: "#e3e3e3",
        base07: "#f7f7f7",
        base08: "#7c7c7c",
        base09: "#999999",
        base0A: "#a0a0a0",
        base0B: "#8e8e8e",
        base0C: "#868686",
        base0D: "#686868",
        base0E: "#747474",
        base0F: "#5e5e5e",
    };

    let cleanEvaluator = cleanObjectKeys(evaluator);

    return (
        <Container component="main" maxWidth={false}>
            <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography variant="h4" component="h4">
                    <Stack direction="row" spacing={2} justify="flex-end" alignItems="center">
                        <FilterAltIcon />
                        <p>Edit evaluator</p>
                    </Stack>
                </Typography>
            </Paper>

            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Paper variant="outlined" sx={{ p: 3, mt: 3 }}>
                        {error && (
                            <Alert color="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}

                        <form onSubmit={submitHandler}>
                            <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
                                Information
                            </Typography>

                            <Paper elevation={1} sx={{ p: 2 }}>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        type="Name"
                                        value={name}
                                        onChange={nameChangeHandler}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <TextField
                                        id="key"
                                        label="Key"
                                        type="key"
                                        value={key}
                                        onChange={keyChangeHandler}
                                    />
                                </FormControl>
                            </Paper>

                            <Typography variant="h5" component="h5" sx={{ mt: 2 }}>
                                Rules
                            </Typography>

                            <IfBlock
                                onBlockChange={onBlockChange}
                                initialEvaluator={initialEvaluator && initialEvaluator.if}
                                nesting={0}
                            />

                            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                                Save
                            </Button>
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Paper variant="outlined" sx={{ p: 3, mt: 3 }}>
                        <JSONTree data={cleanEvaluator} shouldExpandNodeInitially={() => true} theme={theme} />
                    </Paper>
                    <Paper variant="outlined" sx={{ p: 3, mt: 2 }}>
                        <TextField multiline fullWidth rows={4} value={JSON.stringify(cleanEvaluator)} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EvaluatorPage;
