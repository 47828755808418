import { Copyright } from "@mui/icons-material";
import { Box, Container, createTheme, CssBaseline, ThemeProvider, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const RouteLayout = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Header />

                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar />
                    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                        <Outlet />
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default RouteLayout;
