import { Button, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import FlagsTable from "./FlagsTable";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../store/api-actions";

const FlagsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [flags, setFlags] = useState([]);

    const fetchFlags = useCallback(async () => {
        setIsLoading(true);

        try {
            const data = await api("/api/flags", {});

            setFlags(data);
        } catch (e) {
            console.log(e);
            throw new Error("Error while fetching flags");
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchFlags();
    }, [fetchFlags]);

    const deleteHandler = async (selectedRows) => {
        selectedRows.forEach(async (key) => {
            api("/api/flags/" + flags[key].slug, {
                method: "DELETE",
            });
        });
    };

    if (isLoading) {
        return;
    }

    return (
        <Container component="main" maxWidth={false}>
            <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
                    <FlagIcon /> Flags
                </Typography>

                <Button variant="contained" component={Link} to="/flags/new" sx={{ mb: 3 }}>
                    New Flag
                </Button>

                <FlagsTable flags={flags} deleteHandler={deleteHandler} />
            </Paper>
        </Container>
    );
};

export default FlagsPage;
