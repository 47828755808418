import { FormControl, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Blocks, { BlocksTitles } from "./BlocksConfig";

const IfBlock = (props) => {
    const [type, setType] = useState("");
    const [block, setBlock] = useState(null);
    const [evaluator, setEvaluator] = useState({});

    const onBlockChange = useCallback(
        (data) => {
            setEvaluator({ ...evaluator, ...data });

            props.onBlockChange({ if: { ...evaluator, ...data } });
        },
        [evaluator, props]
    );

    const onBlockDelete = useCallback(
        (objectKey) => {
            const { [objectKey]: removedProperty, ...remainingEvaluator } = evaluator;

            setEvaluator(remainingEvaluator);

            setBlock(null);

            props.onBlockChange({ if: remainingEvaluator });
        },
        [evaluator, props]
    );

    useEffect(() => {
        if (props.initialEvaluator && Object.keys(props.initialEvaluator).length !== 0) {
            const id = Object.keys(props.initialEvaluator)[0];

            const newBlock = {};

            newBlock[id] = React.createElement(Blocks[Object.keys(props.initialEvaluator)[0]], {
                onBlockChange: onBlockChange,
                onBlockDelete: onBlockDelete,
                nesting: props.nesting + 1,
                key: id,
                id: id,
                parentType: "if",
                initialEvaluator: props.initialEvaluator[Object.keys(props.initialEvaluator)[0]],
            });

            setBlock(newBlock[id]);
        }
    }, [props.initialEvaluator]);

    const typeChangeHandler = (e) => {
        const id = e.target.value;

        const newBlock = {};

        newBlock[id] = React.createElement(Blocks[e.target.value], {
            onBlockChange: onBlockChange,
            onBlockDelete: onBlockDelete,
            nesting: props.nesting + 1,
            key: id,
            id: id,
            parentType: "if",
        });

        setEvaluator((prevState) => {
            return {};
        });

        setBlock(newBlock[id]);

        setType("");
    };

    return (
        <Paper elevation={props.nesting + 1} sx={{ ml: 1 * props.nesting, p: 3, mt: 3, textAlign: "center" }}>
            {!block && (
                <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Condition"
                        onChange={typeChangeHandler}
                    >
                        {Object.keys(Blocks).map((type) => {
                            return (
                                <MenuItem key={type} value={type}>
                                    {BlocksTitles[type]}
                                </MenuItem>
                            );
                        })}
                        ;
                    </Select>
                </FormControl>
            )}

            {block ? block : null}
        </Paper>
    );
};

export default IfBlock;
