import {
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { BlocksTitles } from "./BlocksConfig";

const DoubleOptionsBlock = (props) => {
    const [firstArgument, setFirstArgument] = useState("");
    const [secondArgument, setSecondArgument] = useState("");
    const [firstArgumentType, setFirstArgumentType] = useState("string");
    const [secondArgumentType, setSecondArgumentType] = useState("string");
    const id = props.id;

    useEffect(() => {
        if (props.initialEvaluator) {
            if (typeof props.initialEvaluator[0] === "object") {
                setFirstArgument(props.initialEvaluator[0].var);
                setFirstArgumentType("var");
            } else if (typeof props.initialEvaluator[0] === "boolean") {
                setFirstArgument(props.initialEvaluator[0] ? "true" : "false");
                setFirstArgumentType("boolean");
            } else if (typeof props.initialEvaluator[0] === "number") {
                setFirstArgument(props.initialEvaluator[0]);

                if (props.initialEvaluator[0] % 1 === 0) {
                    setFirstArgumentType("integer");
                } else {
                    setFirstArgumentType("float");
                }
            } else {
                setFirstArgument(props.initialEvaluator[0]);
                setFirstArgumentType("string");
            }

            if (typeof props.initialEvaluator[1] === "object") {
                setSecondArgument(props.initialEvaluator[1].var);
                setSecondArgumentType("var");
            } else if (typeof props.initialEvaluator[1] === "boolean") {
                setSecondArgument(props.initialEvaluator[1] ? "true" : "false");
                setSecondArgumentType("boolean");
            } else if (typeof props.initialEvaluator[1] === "number") {
                setSecondArgument(props.initialEvaluator[1]);

                if (props.initialEvaluator[1] % 1 === 0) {
                    setSecondArgumentType("integer");
                } else {
                    setSecondArgumentType("float");
                }
            } else {
                setSecondArgument(props.initialEvaluator[1]);
                setSecondArgumentType("string");
            }
        }
    }, [props.initialEvaluator]);

    const firstArgumentChangeHandler = (e) => {
        setFirstArgument(e.target.value);

        const block = {};

        block[id] = [
            formatArgument(e.target.value, firstArgumentType),
            formatArgument(secondArgument, secondArgumentType),
        ];

        props.onBlockChange(block);
    };

    const firstArgumentTypeChangeHandler = (e) => {
        setFirstArgumentType(e.target.value);

        const block = {};

        block[id] = [formatArgument(firstArgument, e.target.value), formatArgument(secondArgument, secondArgumentType)];

        props.onBlockChange(block);
    };

    const secondArgumentChangeHandler = (e) => {
        setSecondArgument(e.target.value);

        const block = {};

        block[id] = [
            formatArgument(firstArgument, firstArgumentType),
            formatArgument(e.target.value, secondArgumentType),
        ];

        props.onBlockChange(block);
    };

    const secondArgumentTypeChangeHandler = (e) => {
        setSecondArgumentType(e.target.value);

        const block = {};

        block[id] = [formatArgument(firstArgument, firstArgumentType), formatArgument(secondArgument, e.target.value)];

        props.onBlockChange(block);
    };

    const formatArgument = (argument, type) => {
        if (type === "integer") {
            return parseInt(argument);
        }
        if (type === "float") {
            return parseFloat(argument);
        }
        if (type === "boolean") {
            return argument === "true" || argument === "1" ? true : false;
        }
        if (type === "var") {
            return { var: [argument] };
        }

        return argument;
    };

    const onBlockDelete = () => {
        props.onBlockDelete(id);
    };

    return (
        <Paper elevation={props.nesting + 1} sx={{ ml: 1 * props.nesting, p: 2, mb: 2, mt: 2 }}>
            <Grid item container spacing={2} justify="flex-end" alignItems="center">
                <Grid item xs={2}>
                    {props.parentType && (
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h4" component="div" gutterBottom>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        type="button"
                                        size="small"
                                        onClick={onBlockDelete}
                                    >
                                        <DeleteForeverIcon /> {props.parentType.toUpperCase()}
                                    </Button>
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={props.nesting + 2} sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <TextField
                                id="firstArgument"
                                label="First argument"
                                variant="outlined"
                                value={firstArgument}
                                onChange={firstArgumentChangeHandler}
                                size="small"
                            />
                        </FormControl>
                        <br />
                        <br />
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">First argument type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={firstArgumentType}
                                onChange={firstArgumentTypeChangeHandler}
                            >
                                <MenuItem value="string">string</MenuItem>
                                <MenuItem value="integer">integer</MenuItem>
                                <MenuItem value="float">float</MenuItem>
                                <MenuItem value="boolean">boolean</MenuItem>
                                <MenuItem value="var">var</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={2} align="center">
                    <Card variant="outlined" sx={{ minHeight: 90 }}>
                        <CardContent>{BlocksTitles[props.type]}</CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={props.nesting + 2} sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <TextField
                                id="secondArgument"
                                label="Second argument"
                                variant="outlined"
                                value={secondArgument}
                                onChange={secondArgumentChangeHandler}
                                size="small"
                            />
                        </FormControl>
                        <br />
                        <br />
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Second argument type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={secondArgumentType}
                                label="Type"
                                onChange={secondArgumentTypeChangeHandler}
                            >
                                <MenuItem value="string">string</MenuItem>
                                <MenuItem value="integer">integer</MenuItem>
                                <MenuItem value="float">float</MenuItem>
                                <MenuItem value="boolean">boolean</MenuItem>
                                <MenuItem value="var">var</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DoubleOptionsBlock;
