import { Button, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EvaluatorsTable from "./EvaluatorsTable";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../store/api-actions";

const EvaluatorsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [evaluators, setEvaluators] = useState([]);

    const fetchEvaluators = useCallback(async () => {
        setIsLoading(true);

        try {
            const data = await api("/api/evaluators", {});

            console.log(data);

            setEvaluators(data);
        } catch (e) {
            console.log(e);
            throw new Error("Error while fetching evaluators");
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchEvaluators();
    }, [fetchEvaluators]);

    const deleteHandler = async (selectedRows) => {
        selectedRows.forEach(async (key) => {
            api("/api/evaluators/" + evaluators[key].slug, {
                method: "DELETE",
            });
        });
    };
    if (isLoading) {
        return;
    }

    return (
        <Container component="main" maxWidth={false}>
            <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
                    <FilterAltIcon /> Evaluators
                </Typography>

                <Button variant="contained" component={Link} to="/evaluators/new" sx={{ mb: 3 }}>
                    New Evaluator
                </Button>

                <EvaluatorsTable evaluators={evaluators} deleteHandler={deleteHandler} />
            </Paper>
        </Container>
    );
};

export default EvaluatorsPage;
