import {
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { BlocksTitles } from "./BlocksConfig";

const SingleOptionBlock = (props) => {
    const [argument, setArgument] = useState("");
    const [argumentType, setArgumentType] = useState("string");
    const id = props.id;

    useEffect(() => {
        if (props.initialEvaluator) {
            if (typeof props.initialEvaluator === "object") {
                setArgument(props.initialEvaluator.var);
                setArgumentType("var");
            } else if (typeof props.initialEvaluator === "boolean") {
                setArgument(props.initialEvaluator ? "true" : "false");
                setArgumentType("boolean");
            } else if (typeof props.initialEvaluator === "number") {
                setArgument(props.initialEvaluator);

                if (props.initialEvaluator % 1 === 0) {
                    setArgumentType("integer");
                } else {
                    setArgumentType("float");
                }
            } else {
                setArgument(props.initialEvaluator);
                setArgumentType("string");
            }
        }
    }, [props.initialEvaluator]);

    const argumentChangeHandler = (e) => {
        setArgument(e.target.value);

        const block = {};

        block[id] = formatArgument(e.target.value, argumentType);

        props.onBlockChange(block);
    };

    const argumentTypeChangeHandler = (e) => {
        setArgumentType(e.target.value);

        const block = {};

        block[id] = formatArgument(argument, e.target.value);

        props.onBlockChange(block);
    };

    const formatArgument = (argument, type) => {
        if (type === "integer") {
            return parseInt(argument);
        }
        if (type === "float") {
            return parseFloat(argument);
        }
        if (type === "boolean") {
            return argument === "true" || argument === "1" ? true : false;
        }
        if (type === "var") {
            return { var: argument };
        }

        return argument;
    };

    const onBlockDelete = () => {
        props.onBlockDelete(id);
    };

    return (
        <Paper elevation={props.nesting + 1} sx={{ ml: 1 * props.nesting, p: 2, mb: 2, mt: 2 }}>
            <Grid item container spacing={2} justify="flex-end" alignItems="center">
                <Grid item xs={2}>
                    {props.parentType && (
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h4" component="div" gutterBottom>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        type="button"
                                        size="small"
                                        onClick={onBlockDelete}
                                    >
                                        <DeleteForeverIcon /> {props.parentType.toUpperCase()}
                                    </Button>
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid item xs={2} align="center">
                    <Card variant="outlined" sx={{ minHeight: 90 }}>
                        <CardContent>{BlocksTitles[props.type]}</CardContent>
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <Paper elevation={props.nesting + 2} sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <TextField
                                id="argument"
                                label="Argument"
                                variant="outlined"
                                value={argument}
                                onChange={argumentChangeHandler}
                                size="small"
                            />
                        </FormControl>
                        <br />
                        <br />
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={argumentType}
                                label="Arguemnt type"
                                onChange={argumentTypeChangeHandler}
                            >
                                <MenuItem value="string">string</MenuItem>
                                <MenuItem value="integer">integer</MenuItem>
                                <MenuItem value="float">float</MenuItem>
                                <MenuItem value="boolean">boolean</MenuItem>
                                <MenuItem value="var">var</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SingleOptionBlock;
