import { Button, ButtonGroup } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { JSONTree } from "react-json-tree";
import EditIcon from "@mui/icons-material/Edit";
import { NavLink } from "react-router-dom";

const theme = {
    scheme: "grayscale",
    author: "alexandre gavioli (https://github.com/alexx2/)",
    base00: "#101010",
    base01: "#252525",
    base02: "#464646",
    base03: "#525252",
    base04: "#ababab",
    base05: "#b9b9b9",
    base06: "#e3e3e3",
    base07: "#f7f7f7",
    base08: "#7c7c7c",
    base09: "#999999",
    base0A: "#a0a0a0",
    base0B: "#8e8e8e",
    base0C: "#868686",
    base0D: "#686868",
    base0E: "#747474",
    base0F: "#5e5e5e",
};

const columns = [
    {
        field: "slug",
        headerName: "Slug",
        flex: 1,
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    {
        field: "config",
        headerName: "Config",
        flex: 1,
        renderCell: (params) => {
            return <JSONTree data={JSON.parse(params.value)} shouldExpandNodeInitially={() => true} theme={theme} />;
        },
    },
    {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => {
            return (
                <ButtonGroup>
                    <Button component={NavLink} to={`/evaluators/${params.row.slug}`}>
                        <EditIcon />
                    </Button>
                </ButtonGroup>
            );
        },
    },
];

const EvaluatorsTable = (props) => {
    const [selectedRows, setSelectedRows] = useState([]);

    const selectingRowsHandler = (selectedRows) => {
        console.log(selectedRows);

        setSelectedRows(selectedRows);
    };

    const deleteHandler = async () => {
        props.deleteHandler(selectedRows);
    };

    const rows = [];

    for (let key in props.evaluators) {
        rows.push({ ...props.evaluators[key], key: key, id: key });
    }

    return (
        <>
            <div style={{ height: "auto", overflow: "auto" }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    checkboxSelection
                    autoHeight={true}
                    getRowHeight={() => "auto"}
                    onSelectionModelChange={(newSelectionModel) => {
                        selectingRowsHandler(newSelectionModel);
                    }}
                    selectionModel={selectedRows}
                />
            </div>

            {selectedRows.length > 0 && (
                <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    sx={{ mt: 2 }}
                    onClick={deleteHandler}
                >
                    Delete
                </Button>
            )}
        </>
    );
};

export default EvaluatorsTable;
