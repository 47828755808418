import {
    Alert,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import { JSONTree } from "react-json-tree";
import VariantsBlock from "./VariantBlocks";
import TargetingBlock from "./TargetingBlock";
import { api } from "../../store/api-actions";

const NewFlagPage = () => {
    const [key, setKey] = useState("my.flag");
    const [name, setName] = useState("My flag");
    const [enabled, setEnabled] = useState(true);
    const [flag, setFlag] = useState({
        variants: {},
        state: "ENABLED",
    });

    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const keyChangeHandler = (e) => {
        setKey(e.target.value);

        setError(null);
    };

    const nameChangeHandler = (e) => {
        setName(e.target.value);

        setError(null);
    };

    const enabledChangeHandler = (e) => {
        setEnabled((prevState) => !prevState);

        setFlag((prevState) => {
            let newFlag = { ...prevState };

            newFlag.state = e.target.checked ? "ENABLED" : "DISABLED";

            return newFlag;
        });
    };

    const variantsChangeHandler = (data) => {
        setFlag((prevState) => {
            let newFlag = { ...prevState };

            newFlag.variants = data;

            return newFlag;
        });
    };

    const defaultVariantChangeHandler = (data) => {
        setFlag((prevState) => {
            let newFlag = { ...prevState };

            newFlag.defaultVariant = data;

            return newFlag;
        });
    };

    const targetingChangeHandler = (data) => {
        setFlag((prevState) => {
            let newFlag = { ...prevState };

            newFlag.targeting = data;

            if (data === null) {
                delete newFlag.targeting;
            }

            console.log(newFlag);

            return newFlag;
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (key === "") {
            setError("Key is required");
            return;
        }

        if (name === "") {
            setError("Name is required");
            return;
        }

        api("/api/flags", {
            method: "POST",
            body: JSON.stringify({
                slug: key,
                name: name,
                config: JSON.stringify(flag),
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(() => {
            navigate("/flags");
        });
    };

    const theme = {
        scheme: "grayscale",
        author: "alexandre gavioli (https://github.com/alexx2/)",
        base00: "#101010",
        base01: "#252525",
        base02: "#464646",
        base03: "#525252",
        base04: "#ababab",
        base05: "#b9b9b9",
        base06: "#e3e3e3",
        base07: "#f7f7f7",
        base08: "#7c7c7c",
        base09: "#999999",
        base0A: "#a0a0a0",
        base0B: "#8e8e8e",
        base0C: "#868686",
        base0D: "#686868",
        base0E: "#747474",
        base0F: "#5e5e5e",
    };

    const fullFlag = {};
    fullFlag[key] = flag;

    return (
        <Container component="main" maxWidth={false}>
            <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography variant="h4" component="h4">
                    <Stack direction="row" spacing={2} justify="flex-end" alignItems="center">
                        <FlagIcon />
                        <p>New flag</p>
                    </Stack>
                </Typography>
            </Paper>

            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Paper variant="outlined" sx={{ p: 3, mt: 3 }}>
                        {error && (
                            <Alert color="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}

                        <form onSubmit={submitHandler}>
                            <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
                                Information
                            </Typography>

                            <Paper elevation={1} sx={{ p: 2 }}>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        type="Name"
                                        value={name}
                                        onChange={nameChangeHandler}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <TextField
                                        id="key"
                                        label="Key"
                                        type="key"
                                        value={key}
                                        onChange={keyChangeHandler}
                                    />
                                </FormControl>

                                <FormControlLabel
                                    control={
                                        <Switch checked={enabled} onChange={enabledChangeHandler} label="Enabled" />
                                    }
                                    label="Enabled"
                                    sx={{ mb: 2 }}
                                />
                            </Paper>

                            <Typography variant="h5" component="h5" sx={{ mt: 2 }}>
                                Variants
                            </Typography>

                            <VariantsBlock
                                onVariantsChange={variantsChangeHandler}
                                onDefaultVariantChange={defaultVariantChangeHandler}
                            />

                            <Typography variant="h5" component="h5" sx={{ mt: 2 }}>
                                Targeting
                            </Typography>

                            <TargetingBlock onTargetingChange={targetingChangeHandler} variants={flag.variants} />

                            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                                Create
                            </Button>
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Paper variant="outlined" sx={{ p: 3, mt: 3 }}>
                        <JSONTree data={fullFlag} shouldExpandNodeInitially={() => true} theme={theme} />
                    </Paper>
                    <Paper variant="outlined" sx={{ p: 3, mt: 2 }}>
                        <TextField multiline fullWidth rows={4} value={JSON.stringify(fullFlag)} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NewFlagPage;
