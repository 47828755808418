import CompoundBlock from "./CompoundBlock";

const AndBlock = (props) => {
    const onBlockChange = (data) => {
        props.onBlockChange(data);
    };

    return (
        <CompoundBlock
            onBlockChange={onBlockChange}
            type="and"
            nesting={props.nesting}
            onBlockDelete={props.onBlockDelete}
            id={props.id}
            initialEvaluator={props.initialEvaluator}
        />
    );
};

export default AndBlock;
